import React, { useEffect} from 'react';
import RainbowLaneStyled from '../components/Login/styles/RainbowLaneStyled';
import WrapperStyled from '../components/Login/styles/IndexStyled';
import Button from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import { ReactComponent as ClockIcon } from '../images/icon-clock.svg';
import { navigate } from 'gatsby';
import GoogleTagManager from '../external/GoogleTagManager';
import { TitleStyled,  SubtitleStyled,
  ContainerStyled, } from '../components/styles/sesion-expired/ExpiredStyled';


const Expired = () => {
  useEffect(() => {
    GoogleTagManager.pushToDataLayer({
      'event':'pagina-virtual',
      'page':'/sesion-expirado/',
      'pagename':'Sesión Expirada'
      });
  }, []);

  const Redirect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const channel = urlParams.get('channel') ?? 'movil';
    navigate(`/login/${channel}`);
  };

  return (
    <WrapperStyled>
      <RainbowLaneStyled />
      <ContainerStyled>
        <div>
          <ClockIcon />
          <TitleStyled>Tu sesión ha expirado</TitleStyled>
          <SubtitleStyled>
            Has estado mucho tiempo inactivo, por tu seguridad cerramos tu
            sesión
          </SubtitleStyled>
          <Button
            variant='contained'
            children="Volver a iniciar sesión"
            onClick={Redirect}
          />
        </div>
      </ContainerStyled>
    </WrapperStyled>
  );
};

export default Expired;
