import styled from '@emotion/styled';
import { BackgroundSSOJPG } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/jpg';

const WrapperStyled = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas: 'background' 'rainbow' 'buttons';

  & > div {
    grid-area: rainbow;
  }

  & > section {
    grid-area: background;
  }

  & > aside {
    grid-area: buttons;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 5fr 3fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'rainbow rainbow' 'background buttons';
  }

  & > section {
    background-image: url(${ BackgroundSSOJPG });
    background-size: cover;
    display: flex;
    background-position: bottom;
    text-align: center;
    align-items: flex-end;
    & > div {
      padding: 16px;
      max-width: 312px;
      margin: 0 auto 32px auto;
      @media screen and (min-width: 768px) {
        margin-bottom: 88px;
        max-width: 420px;
        padding: 16px 0;
      }
    }
  }
  & > aside {
    padding: 0 16px;
    & > div {
      margin: auto;
      max-width: 312px;
      text-align: center;
      @media screen and (min-width: 768px) {
        max-width: 364px;
      }
      & > header {
        padding-top: 32px;
        display: flex;
        justify-content: center;
        @media screen and (min-width: 768px) {
          padding: 14vh 0;
        }
      }
      & > section {
        padding-top: 32px;
      }
      & > footer {
        padding-top: 16px;
        display: grid;
        gap: 4px;
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
        text-align: center;
        @media screen and (min-width: 768px) {
          gap: 16px;
        }
      }
    }
  }
`;

const TypographyContainerStyled = styled.div`
  display: grid;
  gap: 4px;
  padding-bottom: 8px;
  @media screen and (min-width: 768px) {
    padding-bottom: 24px;
  }
`;

export {
  WrapperStyled as default,
  TypographyContainerStyled,
};
