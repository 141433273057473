
const pushToDataLayer = (params: Record<string, any>) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    window.dataLayer.push(params);
  }

  return null;
};

const GoogleTagManager = {
  pushToDataLayer
};

export default GoogleTagManager;
