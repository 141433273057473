import styled from '@emotion/styled';
import { colors } from '@womcorp/wom-miwom-ui-kit/dist/@theme/colors';

const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 350px;
    text-align: center;
  }
`;

const SubtitleStyled = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.Neutros90};
  display: block;
  margin-bottom: 15px;
`;

const TitleStyled = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.Neutros90};
`;

export {ContainerStyled, SubtitleStyled, TitleStyled};